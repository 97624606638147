export default {
    namespaced: true,

    state: {
        totalItem: 0, // -> Total de Items 
        itemsProcessados: 0, // -> Items que já foram processados
        progresso: 0, // -> Progresso (percentual)
        estaAdicionado: [], // -> Indica as tabelas que foram adicionadas (CREATE)
        estaAtualizado: [], // -> Indica as tabelas que foram atualizadas (PUT)
        aAdicionar: 0, // -> Total de tabelas a adicionar
        aAtualizar: 0, // -> Total de tabelas a atualizar

        // ⚙️ PARAMETROS
        minimoParaLoading: 100, // -> Numero minimo de itens para gerar uma barra de loading
    },

    getters: {
        totalItem: (state) => state.totalItem,
        itemsProcessados: (state) => state.itemsProcessados,
        progresso: (state) => state.progresso, 
        estaAdicionado: (state) => state.estaAdicionado,
        estaAtualizado: (state) => state.estaAtualizado,
    },

    actions: {
        async updateTotal({ commit }, valor) {
            commit('SET_TOTAL_ITEM', valor);
        },

        async addTotal({ commit }, valor) {
            commit('ADD_TOTAL_ITEM', valor);
        },

        async updateProcessados({ commit }, valor) {
            commit('SET_ITEMS_PROCESSADOS', valor);
        },

        async addProcessados({ commit, dispatch }, valor) {
            commit('ADD_ITEMS_PROCESSADOS', valor);
            await dispatch('updateProgresso');
        },

        async updateProgresso({ commit, state }) {
            if (state.totalItem > 0) {
                const progressoItens = (state.itemsProcessados / state.totalItem) * 66;
                const totalTabelas = state.aAdicionar + state.aAtualizar;
                const tabelasProcessadas = state.estaAdicionado.length + state.estaAtualizado.length;
                const progressoTabelas = totalTabelas > 0 ? (tabelasProcessadas / totalTabelas) * 34 : 0;
                
                commit('SET_PROGRESSO', Math.ceil(progressoItens + progressoTabelas));
            } else {
                commit('SET_PROGRESSO', 0);
            }
        },

        async addProgresso({ commit }, valor) {
            commit('ADD_PROGRESO', valor);
        },

        async addEstaAtualizado({ commit, dispatch }, valor) {
            commit('ADD_ESTA_ATUALIZADO', valor);
            await dispatch('updateProgresso');
        },

        async addEstaAdicionado({ commit, dispatch }, valor) {
            commit('ADD_ESTA_ADICIONADO', valor);
            await dispatch('updateProgresso');
        },

        async addAAtualizar({ commit }, valor) {
            commit('ADD_A_ATUALIZAR', valor);
        },

        async addAAdicionar({ commit }, valor) {
            commit('ADD_A_ADICIONAR', valor);
        },

        async resetAll({ commit }) {
            commit('SET_ESTA_ADICIONADO', []);
            commit('SET_ESTA_ATUALIZADO', []);
            commit('SET_ITEMS_PROCESSADOS', 0);
            commit('SET_TOTAL_ITEM', 0);
            commit('SET_PROGRESSO', 0);
        },
    },

    mutations: {
        SET_TOTAL_ITEM(state, valor) {
            state.totalItem = valor ?? 0;
        },
        SET_ITEMS_PROCESSADOS(state, valor) {
            state.itemsProcessados = valor ?? 0;
        },
        ADD_ITEMS_PROCESSADOS(state, valor) {
            state.itemsProcessados += valor ?? 0;
        },
        ADD_TOTAL_ITEM(state, valor) {
            state.totalItem += valor ?? 0;
        },
        SET_PROGRESSO(state, valor) {
            state.progresso = valor ?? 0;
        },
        ADD_PROGRESO(state, valor) {
            state.progresso += valor ?? 0;
        },
        ADD_ESTA_ADICIONADO(state, valor) {
            state.estaAdicionado.push(valor ?? null);
        },
        ADD_ESTA_ATUALIZADO(state, valor) {
            state.estaAtualizado.push(valor ?? null);
        },
        ADD_A_ATUALIZAR(state, valor) {
            state.aAtualizar += valor ?? 0
        },
        ADD_A_ADICIONAR(state, valor) {
            state.aAdicionar += valor ?? 0
        },
    },
};
