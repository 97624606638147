import db from "@/plugins/dexie";
import { WsEnviaDados } from "../../websocket";
import geraData from "@/utils/geradata";
import moment from "moment-timezone";

export default {
    enviaSecagem: () => {
        const secagem = db.secagem.where('secidnuvem').equals('null').toArray();
        
        secagem.then((tabela) => {
            tabela.forEach((sec) => {
                console.log('app', sec)
                WsEnviaDados({tipo: 'postSecagem', msg: {...sec, secidusuario: sessionStorage.getItem('user:useidnuvem')}})
            });
        });
    },
    retornoSecagem: ({ data }) => {
        const item = data.body;
        const datanuvem = moment.utc(item.secdataalteracao).subtract(3, 'hours').toISOString();
        console.log('ini', item.secdatainicio)
        const queryGeraData = db.secagem.where({ secdatainicio: geraData(item.secdatainicio) }).toArray();
        queryGeraData.then((tabela) => {
            console.log('tabela', tabela)
            tabela.forEach((linha) => {
                console.log('linha', linha)
                db.secagem.update(linha.id, {secidnuvem: item.id, secdatanuvem: datanuvem, secdataalteracao: datanuvem});
            })
        })
    },
}