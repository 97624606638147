<template>
  <div :value="value">
    <div class="d-flex">
      <div class="input-data">
        <core-date-picker-field 
          :label="label[0]"
          :form="form"
          :readonly="readonly"
          :dense="dense"
          hide-details="hidden"
          :error-messages="activeRule"
          :clearable="clearable"
          v-model="date"/>
      </div>
      <core-time-picker-field
        :label="label[1]"
        :form="form" 
        :readonly="readonly"
        :dense="dense"
        hide-details="hidden"
        :error-messages="activeRule"
        v-model="time"/>
    </div>
    <!-- Campo oculto para validacao -->
    <v-text-field
      class="error-message"
      :value="$$_value"
      readonly
      hide-details="auto"
      :rules="rules"
      :error-messages="activeRule"
      ref="errorMessageInput"
    />
  </div>
</template>

<script>
import CoreDatePickerField from '@/components/core/date-picker-field/Index.vue';
import CoreTimePickerField from '@/components/core/time-picker-field/Index.vue';
import geraData from '@/utils/geradata';

export default {
  name: 'CoreDateTimePickerField',
  components: {
    CoreDatePickerField,
    CoreTimePickerField,
  },
  props: {
    value: {
      type: [String, Date],
    },
    label: {
      type: Array,
      default: () => ['Data', 'Hora'],
    },
    rules: {
      type: Array,
    },
    form: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    clearable: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
    }
  },
  data() {
    return {
      date: this.value.slice(0, 10),
      time: this.value.slice(11, 16),
      activeRule: "",
      defaultValidation: null,
    }
  },
  computed: {
    $$_value() {
      return geraData(this.date + 'T' + this.time);
    },

  },
  watch: {
    date() {
      this.emitValue();
        this.$refs.errorMessageInput.validate = this.defaultValidation;
      this.validate();
      setTimeout(() => {
          this.$refs.errorMessageInput.validate = this.validate;
      }, 500);
    },
    time() {
      this.emitValue();
          this.$refs.errorMessageInput.validate = this.defaultValidation;
      this.validate();
      setTimeout(() => {

          this.$refs.errorMessageInput.validate = this.validate;
      }, 500);
    },
  },
  methods: {
    emitValue() {
      this.$emit('input', this.$$_value);
    },
    validate() {
      if (this.$$_value) {
        this.activeRule = ''; // Reseta mensagem
        this.$props.rules.find(rule => {
          const validationMessage = rule(this.$$_value);
          if (typeof validationMessage === 'string') {
            this.activeRule = validationMessage; // Defie mensagem de erro    
            return true; // Quebra loop no primeiro erro
          }    
          return false;
        });
      }
      return !this.activeRule;
    }
  },
  mounted() {
    /* 
      Quando validate() é chamado no elemento pai, ele cai por padrão no validate do v-text-field e não no desse componente
      
      Basicamente: O validate desse componente depende do validate original do text field funcionando, mas o validate do pai
      não pode executar o validate do text field, mas sim o do methods desse componente.
    */
      this.defaultValidation = this.$refs.errorMessageInput.validate; // Armazena o validate original do text field para substituicao futura
      this.$refs.errorMessageInput.validate = this.validate; // Substitui pelo validate desse componennte
  }
}
</script>

<style scoped>
.input-data {
  width: 110%;
}
.error-message {
  width: 100%;
  pointer-events: none;
  user-select: none;
  cursor: default;
  touch-action: none; 
  margin: 0px !important;
  padding: 0px !important;
  transform: translate(15px, -13px);
}

.error-message >>> input {
  color: transparent !important;
  height: 0px;
}

.error-message >>> .v-input__slot {
  display: none;
  height: 0px;
}
</style>