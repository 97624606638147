<template>
  <div v-if="!loading">
      <div class="d-flex flex-wrap justify-center">
        <div
        v-for="( item, index) in secadores" :key="index">
          <app-card-monitoramento 
            :item="item"
            :key="index"
            @lupa="$_cadastroSecador(item)"
          />
        </div>
      </div>
    <v-dialog v-model="cadastro" max-width="500px" width="80%">
      <app-dialog-secador />
    </v-dialog>
  </div>
</template>

<script>
import db from '@/plugins/dexie'
import moment from 'moment-timezone';
import geraData from '@/utils/geradata';
/* import geraData from '@/utils/geradata';
import moment from 'moment-timezone'; */
import AppCardMonitoramento from '@/components/app/card/monitoramento/Index.vue'
import AppDialogSecador from '@/components/app/dialog/secador/Index.vue'
import { SECADOR_STORAGE } from '@/constants/STORAGE';

export default {
  name: 'ViewMonitoramento',

  components: {
    AppCardMonitoramento,
    AppDialogSecador,
  },

  data() {
    return {
      secadores: [],
      idsecador: null,
      loading: true,
      lotes: null,
      cadastro: null
    }
  },

  methods: {
    async $_load() {
      db.secagem.orderBy('secdatatermino').reverse().toArray().then((item) => {
        item.forEach(e => {
          db.aberturalote.where({ablidaberturalote: e.secidaberturalote}).first().then(lote => {
            e.secidaberturalote = lote
          })
          if (e.secstatus === 'D') {
            e.secdatatermino = null;
          }
          const dataAtual = geraData();
          if(dataAtual > geraData(e.secdatatermino)) {
            e.secstatus = 'F';
          }
          console.log('datas', dataAtual > geraData(e.secdatatermino));
         this.secadores.push(e)

        });
        console.log('secadores',this.secadores)

      });
      this.loading = false;
    },
  
    $_cadastroSecador(secador) {
      console.log('clicou', secador)
      this.idsecador = secador.secsecador;
      sessionStorage.setItem(SECADOR_STORAGE.secagem, JSON.stringify(secador))
      this.cadastro = true;
    },

    FormataData(date, formato) {
      // return moment(date, 'YYYY-MM-DD').format('DD/MM-HH:MM');
      return moment.tz(date, 'America/Sao_Paulo').local().format(formato);
    },
  },

  created() {
    this.$_load();
  }
}
</script>

<style scoped>
  .container:first-child {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .card-wrapper {
    display: flex;
  }

</style>