import Vue from 'vue'
import VueRouter from 'vue-router'

import LayoutDefault from '@/layouts/default/Index.vue'
import LayoutBase from '@/layouts/base/Index.vue'

import SignInView from '../views/sign-in/Index.vue'
import HomeAppBar from '../views/home/AppBar.vue'
import HomeView from '../views/home/Index.vue'
import LoteAppBar from '../views/lote/AppBar.vue'
import LoteView from '../views/lote/Index.vue'
import LoteFilter from '../views/lote/Filters.vue'
import LoteFinalizadosAppBar from '../views/lotesfinalizados/AppBar.vue'
import LoteFinalizadosView from '../views/lotesfinalizados/Index.vue'
import ProducaoAppBar from '../views/ordemproducao/AppBar.vue'
import ProducaoView from '../views/ordemproducao/Index.vue'
import ProducaoFilter from '../views/ordemproducao/Filters.vue'
import LoteBView from '../views/loteb/Index.vue'
import LoteBAppBar from '../views/loteb/AppBar.vue'
import LoteBFilter from '../views/loteb/Filters.vue'
import LoteCView from '../views/lotec/Index.vue'
import LoteCAppBar from '../views/lotec/AppBar.vue'
import LoteCFilter from '../views/lotec/Filters.vue'
import SecadorView from '../views/secador/Index.vue'
import SecadorAppBar from '../views/secador/AppBar.vue'
import SecadorFilter from '../views/secador/Filters.vue'
import PosicaoArmazemView from '../views/posicaoarmazem/Index.vue'
import PosicaoArmazemAppBar from '../views/posicaoarmazem/AppBar.vue'
import CadastroProducaoAppBar from '../views/cadastroproducao/AppBar.vue'
import CadastroProducaoView from '../views/cadastroproducao/Index.vue'
import CadastroProducaoReadonlyAppBar from '../views/readonly/AppBar.vue'
import CadastroProducaoReadonlyView from '../views/readonly/Index.vue'
import QRView from '../views/camera_qr/Index.vue'
import QRAppBar from '../views/camera_qr/AppBar.vue'
import TransferenciaView from '../views/transferencia/Index.vue'
import TransferenciaAppBar from '../views/transferencia/AppBar.vue'
import MonitoramentoView from '../views/monitoramento/Index.vue'
import MonitoramentoAppBar from '../views/monitoramento/AppBar.vue'

import { ROUTE_SIGN_IN } from '@/constants/ROUTES';
import { TOKENLOCAL } from '@/constants/STORAGE'

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}

function validaToken(rota, next) {
  const token = getSessionStorage(TOKENLOCAL.tokenlocal);

  if (!token) {
    next({ path: rota });
  } else {
    next();
  }
}

// import BaseLayout from '../layouts/base/Index.vue'

Vue.use(VueRouter)

// viewRoute({
//   name: ROUTE_HOME.name,
//   view: View,
//   appBar: AppBar,
// }, requireAuthenticated),

const routes = [
  {
    path: '/home',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: HomeAppBar,
        default: HomeView,
      },
      name: 'home',
      path: '',
      beforeEnter: ((to, from, next) => {
        validaToken( ROUTE_SIGN_IN.path, next );
      }),
    }],
  },
  {
    path: '/lote',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: LoteAppBar,
        default: LoteView,
        filters: LoteFilter,
      },
      name: 'lote',
      path: '',
      beforeEnter: ((to, from, next) => {
        validaToken( ROUTE_SIGN_IN.path, next );
      }),
    }],
  },
  {
    path: '/lote/finalizados',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: LoteFinalizadosAppBar,
        default: LoteFinalizadosView,
      },
      name: 'lotesfinalizados',
      path: '',
      beforeEnter: ((to, from, next) => {
        validaToken( ROUTE_SIGN_IN.path, next );
      }),
    }],
  },
  {
    path: '/producao',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: ProducaoAppBar,
        default: ProducaoView,
        filters: ProducaoFilter,
      },
      name: 'producao',
      path: '',
      beforeEnter: ((to, from, next) => {
        validaToken( ROUTE_SIGN_IN.path, next );
      }),
    }],
  },
  {
    path: '/producao/cadastro',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: CadastroProducaoAppBar,
        default: CadastroProducaoView,
      },
      name: 'cadastroproducao',
      path: '',
      beforeEnter: ((to, from, next) => {
        validaToken( ROUTE_SIGN_IN.path, next );
      }),
    }],
  },
  {
    path: '/apontamento/readonly',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: CadastroProducaoReadonlyAppBar,
        default: CadastroProducaoReadonlyView,
      },
      name: 'readonly',
      path: '',
      beforeEnter: ((to, from, next) => {
        validaToken( ROUTE_SIGN_IN.path, next );
      }),
    }],
  },
  {
    path: '/qr',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: QRAppBar,
        default: QRView,
      },
      name: 'qr',
      path: '',
      beforeEnter: ((to, from, next) => {
        validaToken( ROUTE_SIGN_IN.path, next );
      }),
    }],
  },
  {
    path: '/loteb',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: LoteBAppBar,
        default: LoteBView,
        filters: LoteBFilter,
      },
      name: 'loteb',
      path: '',
      beforeEnter: ((to, from, next) => {
        validaToken( ROUTE_SIGN_IN.path, next );
      }),
    }],
  },
  {
    path: '/lotec',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: LoteCAppBar,
        default: LoteCView,
        filters: LoteCFilter,
      },
      name: 'lotec',
      path: '',
      beforeEnter: ((to, from, next) => {
        validaToken( ROUTE_SIGN_IN.path, next );
      }),
    }],
  },
  {
    path: '/secador',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: SecadorAppBar,
        default: SecadorView,
        filters: SecadorFilter,
      },
      name: 'secador',
      path: '',
      beforeEnter: ((to, from, next) => {
        validaToken( ROUTE_SIGN_IN.path, next );
      }),
    }],
  },
  {
    path: '/posicaoarmazem',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: PosicaoArmazemAppBar,
        default: PosicaoArmazemView,
      },
      name: 'posicaoarmazem',
      path: '',
      beforeEnter: ((to, from, next) => {
        validaToken( ROUTE_SIGN_IN.path, next );
      }),
    }],
  },
  {
    path: '/transferenciaproducao',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: TransferenciaAppBar,
        default: TransferenciaView,
      },
      name: 'transferenciaproducao',
      path: '',
      beforeEnter: ((to, from, next) => {
        validaToken( ROUTE_SIGN_IN.path, next );
      }),
    }],
  },
  {
    path: '/monitoramento',
    component: LayoutDefault,
    children: [{
      components: {
        appBar: MonitoramentoAppBar,
        default: MonitoramentoView,
      },
      name: 'monitoramento',
      path: '',
      beforeEnter: ((to, from, next) => {
        validaToken( ROUTE_SIGN_IN.path, next );
      }),
    }],
  },
  redirectRoute('/'),
  {
    path: '/',
    component: LayoutBase,
    children: [{
      components: {
        default: SignInView,
      },
      name: 'signin',
      path: '',
    }],
  },
  redirectRoute('/'),
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export function redirectRoute(redirect) {
  return {
    path: '*',
    redirect,
  };
}

export default router

