import db from "@/plugins/dexie"
import moment from "moment-timezone";
import dadosTabelas from "./local/tabelas"
import apirecebimentoweb from '@/services/api-recebimentoweb';
import { USER_STORAGE  } from '@/constants/STORAGE'
import geraData from "./geradata";

import store from '@/store/index';

function getSessionStorage(key, defaultValue) {
    return sessionStorage.getItem(key) ?? defaultValue;
}

export default async function $_atualizaLocal() {
    store.dispatch('atualizalocal/updateProcessados', 0);
    store.dispatch('atualizalocal/updateTotal', 0);
    const info = dadosTabelas();

    const arrayDataAtualizacao = await Promise.all(Object.entries(info).map(async ([ nome, dados ]) => {

        const dataatt = await db[nome].orderBy(dados.campoatt).filter(item => item[dados.campoatt] !== null).last();
        const dataattFinal = dataatt?.[dados.campoatt] || '01-01-1997 13:00:00';
        return {
            tabela: dados.nomeapi || nome, 
            tabelaApp: nome,
            data: moment.utc(dataattFinal).format('YYYY-MM-DD HH:mm:ss'), 
        }
    }));

    const retorno = await apirecebimentoweb.post(`all/itemsaatualizar`, arrayDataAtualizacao);
    $_geraTotal(retorno.data);
    retorno.data.forEach((item) => {
        console.log('tabela');
        $_geraLog(item);
        $_manipulaTabela(item, info);
        // $_manipulaTabelaComDelay(item, info);
    })
    // console.time('$$$');
    // await $_manipulaTabela(retorno.data[0], info);
    // console.timeEnd('$$$');
}

// eslint-disable-next-line no-unused-vars
async function $_geraLog({ data, tabela }) {
    console.log('%cTabela: ' + tabela, 'font-weight: bold; border-top: 1px solid #fff; padding-top: 3px')
    console.log('%c' + data.length + '%c itens para criação ou atualização', 'color: ' +
        (data.length > 0) ? '#f65' : '#5cc', 'color: #fff');
}

// eslint-disable-next-line no-unused-vars
async function $_manipulaTabela({ data, tabela }, info) {
    let idnuvem = await db[tabela].toArray();
    idnuvem = idnuvem.map((item) => item[`${info[tabela].prefixo}idnuvem`]);

    await $_deletaLocal(idnuvem, tabela);

    if (!idnuvem) { idnuvem = []; }
    
    const novosItens = [];
    const modificacoes = [];
    
    const totalItems = data.length;

    let processedItems = 0;
    const progressThreshold = Math.floor(totalItems * 0.01) || 1;

    let possuiUpdates = false;
    let possuiCreates = false;

    data.forEach((item) => {
        const itemFormatado = info[tabela].model(item);
        if (idnuvem.includes(item.id)) {
            if (!possuiUpdates) {
                possuiUpdates = true;
                store.dispatch('atualizalocal/addAAtualizar', 1);
            }
            modificacoes.push({
                id: item.id,
                updates: itemFormatado
            });
        } else {
            if (!possuiCreates) {
                possuiCreates = true;
                store.dispatch('atualizalocal/addAAdicionar', 1);
            }
            novosItens.push(itemFormatado);
        }
        
        processedItems++;

        if (processedItems >= progressThreshold) {
            store.dispatch('atualizalocal/addProcessados', processedItems);
            processedItems = 0;
        }
    });

    if (processedItems > 0) {
        store.dispatch('atualizalocal/addProcessados', processedItems);
    }

    if (modificacoes.length > 0) {
        try {
            await db[tabela].bulkUpdate(modificacoes);
            store.dispatch('atualizalocal/addEstaAtualizado', tabela);
        } catch (err) {
            console.error("Bulk Update Error:", err);
            store.dispatch('atualizalocal/addEstaAtualizado', { tabela, erro: err.message });
        }
    }
    
    if (novosItens.length > 0) {
        try {
            await db[tabela].bulkAdd(novosItens, { allKeys: true });
            store.dispatch('atualizalocal/addEstaAdicionado', tabela);
        } catch (err) {
            console.error("Bulk Add Error:", err);
            store.dispatch('atualizalocal/addEstaAdicionado', { tabela, erro: err.message });
        }
    }
}


// ⚠️ Usar somente para testes
// eslint-disable-next-line no-unused-vars
async function $_manipulaTabelaComDelay({ data, tabela }, info) {
    console.log(`⚠️ Atualizando em modo TESTE`);
    
    let idnuvem = await db[tabela].toArray();
    idnuvem = idnuvem.map((item) => item[`${info[tabela].prefixo}idnuvem`]);

    await $_deletaLocal(idnuvem, tabela);

    if (!idnuvem) { idnuvem = []; }

    const novosItens = [];
    const modificacoes = [];

    const totalItems = data.length;
    let processedItems = 0;
    const progressThreshold = Math.floor(totalItems * 0.01);

    let possuiUpdates = false;
    let possuiCreates = false;

    for (let i = 0; i < totalItems; i++) {
        const item = data[i];
        const itemFormatado = info[tabela].model(item);

        if (idnuvem.includes(item.id)) {
            if (!possuiUpdates) {
                possuiUpdates = true;
                store.dispatch('atualizalocal/addAAtualizar', 1);
            }
            modificacoes.push({
                id: item.id,
                updates: itemFormatado
            });
        } else {
            if (!possuiCreates) {
                possuiCreates = true;
                store.dispatch('atualizalocal/addAAdicionar', 1);
            }
            novosItens.push(itemFormatado);
        }

        processedItems++;

        if (processedItems >= progressThreshold) {
            store.dispatch('atualizalocal/addProcessados', progressThreshold);
            processedItems = 0;
        }

        await delay(1);
    }

    if (modificacoes.length > 0) {
        try {
            await db[tabela].bulkUpdate(modificacoes);
            store.dispatch('atualizalocal/addEstaAtualizado', tabela);
        } catch (err) {
            console.error("Bulk Update Error:", err);
            store.dispatch('atualizalocal/addEstaAtualizado', { tabela, erro: err.message });
        }
    }

    if (novosItens.length > 0) {
        try {
            await db[tabela].bulkAdd(novosItens, { allKeys: true });
            store.dispatch('atualizalocal/addEstaAdicionado', tabela);
        } catch (err) {
            console.error("Bulk Add Error:", err);
            store.dispatch('atualizalocal/addEstaAdicionado', { tabela, erro: err.message });
        }
    }
}





async function $_geraTotal(data) {
    console.log(data, 'dataaa');
    let total = 0
    for (const tabela of data) {
        total += tabela.data.length;
    }
    console.log(total,'totaaaaal');
    store.dispatch('atualizalocal/updateTotal', total);
}

// eslint-disable-next-line no-unused-vars
async function $_deletaLocal(idnuvemlocal, tabela) {
    // let { data } = await apirecebimentoweb.get(`${tabela}/idnuvem`)
    // data = await data.map((item) => item.id);


    // idnuvemlocal.forEach((itemlocal) => {
    //     if (!data.includes(itemlocal)) {
    //         setTimeout(() => {
    //             console.log(`%cTabela: ${tabela.nome} | ${itemlocal} deletado!`, 'font-weight: bold; border-top: 1px solid #fff; padding-top: 3px');
    //         }, 100); 
    //         db[tabela.nome].where({ [`${tabela.prefixo}idnuvem`]: itemlocal }).delete();
    //     }
    // })

}

// DELAY PARA TESTES DE PERFORMANCE
function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


export async function $_enviaApontamento() {
    const token = JSON.parse(sessionStorage.getItem('auth:token'));
    let apontamentos = await db.apontamentoproducao.toArray();
    apontamentos = apontamentos.filter(a => a.appdatanuvem === null);
    console.log(apontamentos, 'apontamentossssssssssssss');
    const idusuario = await getSessionStorage(USER_STORAGE.useidusuario);
    apontamentos.forEach((item) => {
            if ((item.appidnuvem) && (item.appidnuvem !== 'null')) {
                console.log('put - ', item);
                const payload = {
                    appidordemproducao: item.appidordemproducao,
                    appidaberturaloteembalagem: item.appidaberturaloteembalagem,
                    appquantidadeembalagem: item.appquantidadeembalagem,
                    // appdatainicio:  moment(item.appdatainicio),
                    appidlocalarmazenamento: item.appidlocalarmazenamento,
                    appidaberturalote: item.appidaberturalote,
                    // appdatatermino: moment(item.appdatatermino),

                    apppeso: item.apppeso,
                    appdatapesagem: item.appdatapesagem,
                    appnumerobag: item.appnumerobag,
                    appnumerobalanca: item.appnumerobalanca,

                    appidturno: item.appidturno,
                    appquantidade: item.appquantidade,
                    appidusuario: idusuario ,
                    appdataalteracao: geraData(),
                    appposicaoarmazem: item.appposicaoarmazem,
                    id: item.appidnuvem,
                }
                apirecebimentoweb.put(`/apontamentoproducao/${item.appidnuvem}`, payload, {
                    headers: {
                        Authorization: `Bearer ${token.token}`
                    }
                }).then((res) => {
                    const alteracao = (moment.utc(res.data.appdataalteracao).subtract(3, 'hours').format('YYYY-MM-DDTHH:mm:ss'))
                    db.apontamentoproducao.update(item.id, {
                        appdataalteracao: alteracao,
                        appdatanuvem: alteracao,
                    });
                });    
            } else {
                console.log('post - ', item);
                const payload = {
                    appidordemproducao: item.appidordemproducao,
                    appidaberturaloteembalagem: item.appidaberturaloteembalagem,
                    appquantidadeembalagem: item.appquantidadeembalagem,
                    // appdatainicio:  moment(item.appdatainicio),
                    appidlocalarmazenamento: item.appidlocalarmazenamento,
                    appidaberturalote: item.appidaberturalote,

                    apppeso: item.apppeso,
                    appdatapesagem: item.appdatapesagem,
                    appnumerobag: item.appnumerobag,
                    appnumerobalanca: item.appnumerobalanca,
                    appidlocalmovimentacaoestoque: item.appidlocalmovimentacaoestoque,

                    // appdatatermino: moment(item.appdatatermino),
                    appidturno: item.appidturno,
                    appquantidade: item.appquantidade,
                    appidusuario: idusuario ,
                    appdataalteracao: geraData(),
                }
                apirecebimentoweb.post('/apontamentoproducao', payload, {
                    headers: {
                        Authorization: `Bearer ${token.token}`
                    }
                }).then((res) => {
                    console.log(res, 'res');
                    const alteracao = (moment.utc(res.data.appdataalteracao).subtract(3, 'hours').format('YYYY-MM-DDTHH:mm:ss'))
                    db.apontamentoproducao.update(item.id, {
                        appidusuario: res.data.appidusuario,
                        appidnuvem: res.data.id,
                        appdataalteracao: alteracao,
                        appdatanuvem: alteracao,
                    });
                });
            }
    }); 
}

export async function SetupEnvioLocal({ silent = false } = {}) {
    if (navigator.onLine) {
        $_atualizaLocal(silent);
        $_enviaApontamento();
    }
    // window.addEventListener('offline', () => {
    //     console.log('Atualmente Offline');
    // });
    // window.addEventListener('online', () => {
    //     console.log('Atualmente Online');
    //     $_atualizaLocal();
    //     $_enviaApontamento();
    // });
}
