<template>
    <core-view class="flex-column" fill-height>
        <v-overlay v-show="(progresso !== 100) && (totalItem > minimoParaLoading)">
            <v-snackbar timeout="2000000" v-model="snackbar">
                <p>Grande número de itens para atualizar, aguarde...</p>
                <p>{{ itemsProcessados }} de {{ totalItem }}</p>
                <v-progress-linear color="primary" :value="progresso"></v-progress-linear>
            </v-snackbar>
        </v-overlay>

        <v-snackbar 
          v-if="totalItem > 10"
          color="primary"
          timeout="6000"
          v-model="snackbarSucesso"
        >
          <v-icon class="snackbar__icon">
            mdi-check-circle
          </v-icon>
          Dados atualizados com sucesso!
        </v-snackbar>
    </core-view>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CoreView from '@/components/core/view/Index.vue';
import { TOKEN, USER_STORAGE } from '@/constants/STORAGE';
import { SetupEnvioLocal } from '@/utils/atualizalocal';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}

export default {
  name: 'ViewHome',

  components: {
    CoreView,
  },

  data() {
    return {
      snackbar: true,
      snackbarSucesso: false,
    };
  },

  computed: {
    ...mapState('atualizalocal', [
      'totalItem',
      'itemsProcessados',
      'progresso',
      'minimoParaLoading',
      'estaAdicionado',
      'estaAtualizado',
    ]),
  },

  watch: {
    progresso(valor) {
      console.log(valor, 'valor');
      if (valor === 100) {
        this.snackbarSucesso = true;
      }
    }
  },

  methods: {
    ...mapActions({
      $_recebimentosFind: 'recebimento/find',
      $_findFila: 'fila/find',
      $_findUserId: 'users/findByWebId'
    }),

    async $_setupIdUsuario() {
      const token = JSON.parse(await getSessionStorage(TOKEN, ''));
      const data = await this.$_findUserId({ id: token.userId });
      sessionStorage.setItem(USER_STORAGE.useidusuario, data.useidusuario);
    }
  },

  created() {
    this.$_setupIdUsuario();
    SetupEnvioLocal();
  },
};
</script>

<style scoped>
.v-sheet--offset {
  top: 5px;
  position: relative;
}

.center {
  width: 100%;
  text-align: center;
}

.snackbar__icon {
  margin-right: 15px;
}
</style>
