/* import { WSModel } from "../../wsmodels";
import { prefixos } from "../../tabelas"; */
import dadosTabelas from "@/utils/ws/tabelas";
const info = dadosTabelas();
import db from "@/plugins/dexie";

export default {
    novoItem: ({ data }) => {
        let tabelaDexie = null;
        const tabelaLocal = ['localmovimentacaoestoque', 'ordemproducao'];
        if (tabelaLocal.includes(data.tabela)) {
          if (data.tabela === 'localmovimentacaoestoque') tabelaDexie = 'localarmazenamento';
          if (data.tabela === 'ordemproducao') tabelaDexie = 'producao';
        } else {
          tabelaDexie = data.tabela;
        }
        // const entity = WSModel[data.tabela](data.novo);
        const entity = info[tabelaDexie].model(data.novo);
        console.log(entity);
        db[tabelaDexie].add(entity);
    },
    //
    atualizaItem: ({ data }) => {
      let tabelaDexie = null;
      const tabelaLocal = ['localmovimentacaoestoque', 'ordemproducao'];
        if (tabelaLocal.includes(data.tabela)) {
          if (data.tabela === 'localmovimentacaoestoque') tabelaDexie = 'localarmazenamento';
          if (data.tabela === 'ordemproducao') tabelaDexie = 'producao';
        } else {
          tabelaDexie = data.tabela;
        }
        const entity = info[tabelaDexie].model(data.novo);
        const campo = `${info[tabelaDexie].prefixo}idnuvem`;
        const objWhere = { [campo]: data.novo.id };
        const itemUpdate = db[tabelaDexie].where(objWhere).first();
        itemUpdate.then((item) => {
            db[tabelaDexie].update(item.id, entity);
        });
    },
    //
    deletaItem: ({ data }) => {
      const adeletar = data.deletar;
      const tabelaLocal = ['localmovimentacaoestoque', 'ordemproducao'];
        if (tabelaLocal.includes(adeletar.loetabela)) {
          if (adeletar.loetabela === 'localmovimentacaoestoque') adeletar.loetabela = 'localarmazenamento';
          if (adeletar.loetabela === 'ordemproducao') adeletar.loetabela = 'producao';
        }
        const exclusao = db[adeletar.loetabela].where(
        `${info[adeletar.loetabela].prefixo}idnuvem`,
        ).equals(adeletar.loeidnuvem).delete();
        exclusao.then((deletou) => {
        if (deletou) {
            db.logexclusao.clear();
            db.logexclusao.add(info.logexclusao.model(adeletar));
        }
        });
    },
}