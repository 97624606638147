import geraData from "../geradata";

function LogExclusao(item) {
  return {
    loedataexclusao: item.loedataexclusao,
  };
}

function Turno(item) {
  return {
      tupidnuvem: item.id,
      tupidturno: item.tupidturno,
      tupdataabertura: item.tupdataabertura,
      tupdatafechamento: item.tupdatafechamento,
      tupobservacao: item.tupobservacao,
      tupdataalteracao: item.tupdataalteracao
  }
}

function AberturaLote(item) {
  return {
      ablidnuvem: item.id,
      ablidaberturalote: item.ablidaberturalote,
      ablnrocontrole: item.ablnrocontrole,
      abldescricao: item.prodescricao,
      ablgranulometria: item.moddescricao,
      abldataalteracao: item.abldataalteracao,
      abldataabertura: item.abldataabertura,
      ablidproduto: item.ablidproduto,
  }
}
function LocalArmazenamento(item) {
  return {
      loaidnuvem: item.id,
      loaidlocalarmazenamento: item.lmeidlocalmovimentacaoestoque,
      loadescricao: item.lmedescricao,
      loadataalteracao: item.lmedataalteracao,
      loacodigoarmazem: item.lmecodigoarmazem,
      loausadoapppesagem: item.lmeusadoapppesagem,
  }
}
function Produto(item) {
  return {
      proidnuvem: item.id,
      prodescricao: item.prodescricao,
      proidproduto: item.proidproduto,
      prodataalteracao: item.prodataalteracao,
      progranulometria: item.moddescricao,
      proidvariedade: item.proidvariedade,
  }
}
function Fabrica(item) {
  return {
      fabidnuvem: item.id,
      fabidfabrica: item.fabidfabrica,
      fabdescricao: item.fabdescricao,
      fabdataalteracao: item.fabdataalteracao,
  }
}
function OrdemProducao(item) {
  return {
      prcidnuvem: item.id,
      prcidproducao: item.orpidordemproducao,
      prcidfabrica: item.orpidfabrica,
      prcdataabertura: item.orpdataabertura,
      prcdatafechamento: item.orpdatafechamento,
      prcobservacao: item.orpobservacao,
      prcnoordemproducao: item.orpnooremproducao,
      prcdataalteracao: item.orpdataalteracao,
  }
}
function ApontamentoProducao(item) {
  return {
      appidnuvem: item.id,
      appidaberturalote: item.appidaberturalote,
      appdatapesagem: geraData(item.appdatapesagem),
      appobsduplicado: item.appobsduplicado,
      appnumerobag: item.appnumerobag,
      appdataalteracao: item.appdataalteracao,
      appstatus: item.appstatus,
      apppeso: item.apppeso,
      appidlocaltransferenciaproducao: item.appidlocaltransferenciaproducao,
      appdatanuvem: item.appdataalteracao,
  }
}
function Variedade(item) {
  return {
      varidnuvem: item.id,
      varidvariedade: item.varidvariedade,
      vardescricao: item.vardescricao,
      vardataalteracao: item.vardataalteracao,
  }
}
function Secagem(item) {
  return {
      secidnuvem: item.id,
      secdatanuvem: item.secdataalteracao,
      secidusuario: item.secidusuario,
      secidsecagem: item.secidsecagem,
      secidaberturalote: item.secidaberturalote,
      secdataalteracao: item.secdataalteracao,
      secstatus: item.secstatus,
      secumidade: item.secumidade,
      secsecador: item.secsecador,
      secdatainicio: geraData(item.secdatainicio),
      secdatatermino: geraData(item.secdatatermino),
  }
}
// ESLint prefere default, mas não permite exportar variavel como default;

export const WSmodel = {
  logexclusao: LogExclusao,
  turno: Turno,
  aberturalote: AberturaLote,
  localarmazenamento: LocalArmazenamento,
  produto: Produto,
  fabrica: Fabrica,
  ordemproducao: OrdemProducao,
  apontamentoproducao: ApontamentoProducao,
  variedade: Variedade,
  secagem: Secagem
};
