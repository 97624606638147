
import db from "@/plugins/dexie";
import { WsEnviaDados } from "../../websocket";
import dadosTabelas from "../../tabelas";


const info = dadosTabelas();
console.log('info', info)
export default {
    enviaDataAtt: async ({ data }) => {
        const token = JSON.parse(sessionStorage.getItem('auth:token'));
        const aAtualizar = Object.keys(info).filter((item) => {
            return ![
                // NAO IGNORAR, TRAZER SOMENTE COM ID DO USUARIO É IGUAL
                ""]
            .includes(item);
        });
        const datasAtt = await Promise.all(aAtualizar.map(async (tabela) => {
            if (tabela === 'logexclusao') {
                const table = await db.logexclusao.toArray();
                return { adeletar: table, tabela: tabela };
            }
            const dataatt = await db[tabela]
                .orderBy(`${info[tabela].campoatt}`)
                .reverse()
                .first();
            if (dataatt) {
                return { att: dataatt[`${info[tabela].campoatt}`], userId: token.userId, tabela: info[tabela]?.nomeapi || tabela, psecador: true};
                // return { att: dataatt[`${info[tabela].campoatt}`], userId: token.userId, tabela: info[tabela]?.nomeapi || tabela, psecador: true};
            }
            return { att: null, userId: token.userId, tabela: info[tabela]?.nomeapi || tabela, psecador: true };

        }));
        if (datasAtt.length > 0) {
            WsEnviaDados({ tipo: 'verificadataatt', msg: datasAtt, connectionId: data })
        }
    },
    ////// APONTAMENTO SINCRONIZACAO
    sincronizaLocal: async () => {
        const aAtualizar = (Object.keys(info)).filter((item) => {
            return ['apontamentoproducao']
            .includes(item);
        });

        const post = [];
        const put = [];

        for (const item of aAtualizar) {
            const tabela = await db[item].toArray();
            
            for (const entidade of tabela) {
                for (const key of Object.keys(entidade)) {
                    if(entidade[key] === null) delete entidade[key]
                }
                if ( entidade.appdataalteracao >  entidade.appdatanuvem) {
                    put.push(entidade)
                } else if (!entidade.appdatanuvem) {
                    post.push({...entidade, appidusuario: sessionStorage.getItem('user:useidnuvem')})
                }
            }
            if (Object.keys(post).length > 0) {
                if(item === 'apontamentoproducao') await WsEnviaDados({ tipo: 'postApontamentoProducao', msg: post });
                if(item === 'secagem') await WsEnviaDados({ tipo: 'postSecagem', msg: post });
            }
            if (Object.keys(put).length > 0) {
                if(item === 'apontamentoproducao') await WsEnviaDados({ tipo: 'updateApontamentoProducao', msg: put });
                if(item === 'secagem') await WsEnviaDados({ tipo: 'updateSecagem', msg: put });
            }
        }
    },
    updategeral: async ({ data }) => {
        data.forEach(async (alteracao) => {
            const tabelasLocais = {
                'localmovimentacaoestoque': { tabela: 'localarmazenamento'} ,
                'ordemproducao': {tabela: 'producao' } ,
                'turnoproducao': {tabela: 'turno'},
            }
            let tabLocal = null;
                Object.entries(tabelasLocais).some(item => {
                if (item[0] === alteracao.tabela)
                tabLocal = item[1].tabela
            });
            // Procurar alguma forma de trazer apenas uma propriedade
            const itensTabelalocal = await db[tabLocal || alteracao.tabela].toArray();
            alteracao.entities.forEach((entity) => {
                if (alteracao.tabela === 'logexclusao') {
                    try {
                        const db = db[entity.loetabela].where({id: 1})
                    } catch {
                        return;
                    }
                    if (entity.loetabela === 'localmovimentacaoestoque') tabLocal = 'localarmazenamento';
                    if (entity.loetabela === 'ordemproducao') tabLocal = 'producao';
                    if (entity.loetabela === 'turnoproducao') tabLocal = 'turno';
                    const exclusao = db[tabLocal || entity.loetabela].where(
                        `${info[tabLocal || entity.Loetabela].prefixo}idnuvem`,
                    ).equals(entity.loeidnuvem).delete();
                    exclusao.then((deletou) => {
                        if (deletou) {
                            db.logexclusao.clear();
                            db.logexclusao.add(info.logexclusao.model(entity));
                        }
                    });
                } else {
                const campoIdNuvem = `${info[tabLocal || alteracao.tabela].prefixo}idnuvem`;
                let existe = false;
                console.log(entity, 'tbt');
                itensTabelalocal.forEach((itemTabelaLocal) => {
                    // Verificar
                    if (itemTabelaLocal[campoIdNuvem] === entity.id) {
                        existe = itemTabelaLocal.id;
                    }
                });
                setTimeout(() => {
                    if (existe) {
                        db[tabLocal || alteracao.tabela].update(existe, info[tabLocal || alteracao.tabela].model(entity));
                    } else {
                        db[tabLocal || alteracao.tabela].add(info[tabLocal || alteracao.tabela].model(entity));
                    }
                    }, 0);
                }
            });
        });
        /////// LANCAMENTO SINCRONIZACAO
        setTimeout(async () => {
            
            const aAtualizar = Object.keys(info).filter((item) => {
                return ['apontamentoproducao', 'secagem'].includes(item);
            });
            const post = [];
            const put = [];
        
            for (const item of aAtualizar) {
                console.log('infoitem', info[item])
                const updates = await db[item].toArray();
                for (const update of updates) {
                  for (const key of Object.keys(update)) {
                    if(update[key] === null) delete update[key]
                  }
                    if (update[info[item].campoatt] > update[`${info[item].prefixo}datanuvem`]) {
                        put.push(update)
                    } else if (!update[`${info[item].prefixo}datanuvem`]) {
                        post.push({...update, [`${info[item].prefixo}idusuario`]: sessionStorage.getItem('user:useidnuvem')});
                    }
                }
              if (Object.keys(post).length > 0) {
                if(item === 'apontamentoproducao') await WsEnviaDados({ tipo: 'postApontamentoProducao', msg: post });
                if(item === 'secagem') await WsEnviaDados({ tipo: 'postSecagem', msg: post });
              }
              if (Object.keys(put).length > 0) {
                if(item === 'apontamentoproducao') await WsEnviaDados({ tipo: 'updateApontamentoProducao', msg: put });
                if(item === 'secagem') await WsEnviaDados({ tipo: 'updateSecagem', msg: put });
              }
            }
        }, 1000);
    }
}