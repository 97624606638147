<template>
  <div>
    <div class="container">
        <v-form ref="form" lazy-validation>
          <form-config
            ref="config"
            :formulario="formulario"
            tabela="apontamentoproducao"
          />
            <div class="d-flex justify-center contaainer">
              <v-text-field ref="secador"
              outlined label="Secador" class="textfield n-center"
              background-color="white" type="number"
              :rules="rules.secsecador"
              :dense="!$_isMobile"
              v-model="formulario.secsecador"
              @keyup.enter="$refs.secador.blur(); $refs.lote.$children[0].focus();"></v-text-field>
              <!-- <p>Selecione um lote para criar um novo apontamento.</p> -->
              <card-auto-complete ref="lote"
              label="Lote P.A."
              class="textfield n-center"
              :dados="lotesdaop" 
              :dense="!$_isMobile"
              v-model="formulario.secidaberturalote"
              itemLabel="ablnrocontrole"  
              item-value="ablidaberturalote"
              :return-object="false"
              hide-details
              icon="mdi-qrcode-scan"
              @focus="$_scrollUp($event)"
              :action="$_onClickQR"
              @keyup.enter="$refs.lote.$children[0].blur(); $refs.umidade.focus()"
            />
                  <v-card class="descricaoextra" v-if="lotecompleto?.abldescricao">
                    <b class="centerextra" style="font-weight: 600;">{{lotecompleto?.abldescricao}}</b>
                    <v-divider></v-divider>
                    <b class="centerextra" style="font-weight: 600;">{{lotecompleto?.ablgranulometria}}</b>
                    <v-divider></v-divider>
                    <b class="centerextra" style="font-weight: 600;">{{lotecompleto?.vardescricao}}</b>
                    <v-divider v-if="lotecompleto?.apppeso"></v-divider>
                    <b class="centerextra" style="font-weight: 600;">{{lotecompleto?.apppeso}}</b>
                  </v-card>
              <!-- <v-text-field outlined label="Data/Hora" class="textfield n-center" readonly
              v-model="formulario.appdatapesagem"></v-text-field> -->
              <v-text-field ref="umidade"
              :dense="!$_isMobile"
              outlined label="% Umidade" class="textfield n-center" type="number"
              background-color="white" :rules="rules.secumidade"
              v-model="formulario.secumidade"
              @keyup.enter="$refs.umidade.blur(); $_submit"></v-text-field>
              <core-date-time-picker-field
                hide-details
                form
                :dense="false"
                v-model="formulario.secdatainicio"
                :label="['Data inicio', 'Hora']"
                :rules="rules.secdatainicio"
              />
            <btn-auto-complete 
              label="Status"
              class="n-center"
              :dados="statussecador"
              :forcarDense="false"
              icon="mdi-chevron-down"
              v-model="formulario.secstatus"
              :returnObject="false"
              item-label="status"
              item-value="secstatus"
            />
              <v-btn color="primary" class="salvar" @click="$_submit">Salvar</v-btn>
            </div>
              <!-- <v-btn @click="$_cancelar">Cancelar</v-btn> -->
        </v-form>
      <!-- <v-text-field></v-text-field> -->
      <br/>
    </div>
  </div>
</template>

<script>

// import errorHandler from '@/utils/error-handler';

import { ROUTE_PRODUCAO_CADASTRO,  ROUTE_QR /*ROUTE_LOTES_FINALIZADOS*/} from '@/constants/ROUTES';

import FormConfig from '@/components/form/components/form-config/Index.vue';
import CardAutoComplete from '@/components/core/card-auto-complete/Index.vue'
import CoreDateTimePickerField from '@/components/core/date-time-picker-field/Index.vue'
import BtnAutoComplete from '@/components/core/btn-auto-complete_v2/Index.vue'

import { OPLOCAL_STORAGE } from '@/constants/STORAGE'

import $_scrollUp from '@/utils/scroll-up'


// import { OPLOCAL_STORAGE, CADPRODUCAO_STORAGE } from '@/constants/STORAGE';

import db from '@/plugins/dexie';

import moment from 'moment-timezone';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}


// import AppCardAtividadeSkeleton from '../../components/app/card/atividade/Skeleton.vue';
import { SetupWebSocket } from '../../utils/ws/websocket';
import errorHandler from '@/utils/error-handler';
import geraData from '@/utils/geradata';

export default {
  name: 'ViewLoteC',

  components: {
    FormConfig,
    CardAutoComplete,
    CoreDateTimePickerField,
    BtnAutoComplete
  },

  data() {
    return {
      rotaFiltros: [
        ROUTE_PRODUCAO_CADASTRO,
      ],

      fabrica: null,
      loading: true,
      lotes: null,
      lotesdaop: null,
      lotesfechados: null,
      proximolote: null,
      proximoembalagem: null,
      idordemproducao: null,
      lotecompleto: null,

      formulario: {
        secsecador: null,
        secdatainicio: geraData(),
        secdataalteracao: null,
        secumidade: null,
        secidaberturalote: null,
        secstatus: null,
      },
      statussecador: [{
        status:'Ativado', secstatus:'A'
        },
        {
        status:'Desativado', secstatus:'D'
        },
        {
        status:'Processando', secstatus:'P'
        },
      ],
      rules: {
        secsecador: [ v => !!v || 'Secador é obrigatório' ],
        secumidade: [ v => !!v || 'Umidade é obrigatório' ],
        secdatainicio: [ v => !!v || 'Data de inicio não pode ser vazia' ],
      },

      // ESSA VIEW ESTÁ COM VARIAS FUNÇÕES A MAIS, RETIRAR DEPOIS
      

      itensComEsseNro: null,

      lotesMaisRecentes: [],

      abrirdialog: false,

      uaaa: false,

      w_peso: null,
    };
  },

  computed: {
    $_isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    $$ablidaberturalote() {
      return this.formulario.secidaberturalote;
    }
  },

  methods: {
    async  $_scrollUp(e) {
        $_scrollUp(e);
    },
    async $_setup() {
      this.lotesdaop = await db.aberturalote.orderBy('ablnrocontrole').toArray();

      const formulario = sessionStorage.getItem('formulario', null);
      if (formulario) {
        this.formulario = JSON.parse(formulario);
        sessionStorage.removeItem('formulario');
      }
    },

    async $_submit() {
      if (this.$refs.form.validate()) {
      try{
        this.formulario.secdatatermino = moment().add(12, 'hours').toISOString();
        this.formulario.secdataalteracao = moment().toISOString();
        // this.formulario.secdatainicio.setHours(this.formulario.secdatainicio.getHours() - 3);
        delete this.formulario.id
        console.log(this.formulario);
        let a;
          const updateSecador = await db.secagem.where({secsecador: this.formulario.secsecador}).first();
          if (updateSecador) {
            // talvez fazer um pop-up com informações da ultima secagem daquele secador para poder confirmar
            // a atualização
            a = await db.secagem.update(updateSecador.id, this.formulario)
          }else {
            a = await db.secagem.add(this.formulario)//await this.$refs.config.$_submit();
          }
          Object.keys(this.formulario).forEach(k => this.formulario[k] = null);
          if (a) {
            errorHandler('Enviado com sucesso!');
            sessionStorage.setItem(OPLOCAL_STORAGE.qr, '');
            Object.keys(this.formulario).forEach(k => this.formulario[k] = null);
            this.$refs.form.resetValidation()
          }
      } catch (e) {
          console.log(e, 'eeeee');
          if(e.code == 'ERR_NETWORK'){
            errorHandler('Erro de conexão');
          } else {
            errorHandler(e);
          }
      }
      } else {
        errorHandler('Formulario inválido, verifique os dados e envie novamente');
      }
    },

/*     $_somaPeso(nro) {
      const itensComEsseNro = this.lotes.filter((item) => {
        return item.applote === nro;
      })

      const soma = itensComEsseNro.reduce((acc, cur) => {
        return acc +  Number(cur.appquantidade);
      }, 0);
      return soma;
    }, */

/*     FormataData(date, formato) {
      // return moment(date, 'YYYY-MM-DD').format('DD/MM-HH:MM');
      return moment.tz(date, 'America/Sao_Paulo').local().format(formato);
    }, */

/*     proximoInput() {
      this.$refs.loteb.$children[0].focus();
    }, */

/*     $_openDetalhe(nro) {
      sessionStorage.setItem(OPLOCAL_STORAGE.nro, nro);
      this.$router.replace({ 
        ...this.$route,
        name: ROUTE_LOTES_FINALIZADOS.name,
      });
    }, */

    $_onClickQR() {
      sessionStorage.setItem('formulario', JSON.stringify(this.formulario));
      setTimeout(() => {
        this.$router.push({
          ...this.$route,
          name: ROUTE_QR.name,
        });
      });
    },

    async $_verificaQR() {
      const qr = await getSessionStorage(OPLOCAL_STORAGE.qr, '');
      if (qr && qr.length && qr !== 'null') {
        const lote  = (await db.aberturalote.where({ ablnrocontrole: qr }).toArray());
        if (!lote.length) {
          errorHandler('Lote não encontrado');
          return;
        }
        this.formulario.secidaberturalote = lote[0].ablidaberturalote;
        this.lotecompleto = {...lote[0]}
        console.log(lote[0])
        this.$refs.umidade.focus();
        if (typeof this.proximolote === 'object') {
          // this.$_adicionaLote(this.proximolote);
        } else {
          errorHandler('Erro ao adicionar novo lote')
        }
      }
      // sessionStorage.setItem(OPLOCAL_STORAGE.qr, '');
      // this.proximolote = JSON.parse(sessionStorage.getItem('lote'));
      // this.proximoembalagem= JSON.parse(sessionStorage.getItem('loteembalagem'));
      // this.w_peso = sessionStorage.getItem('peso', this.w_peso);
    }
  },

  created() {
    this.$_setup();
    this.$_verificaQR();
    SetupWebSocket();
  },

  watch: {
    $$ablidaberturalote: {
      handler() {
        setTimeout(() => {
          if (this.formulario.secidaberturalote) {
            this.lotecompleto = null;
            (db.aberturalote.where({ ablidaberturalote: this.formulario.secidaberturalote }).toArray()).then((item) => {
              console.log(item[0], 'aaa');
              (db.produto.where({ proidproduto: item[0].ablidproduto }).toArray()).then((itemProduto) => {
                console.log('itemproduto', itemProduto);
                if (itemProduto[0]?.proidvariedade) {
                (db.variedade.where({ varidvariedade: itemProduto[0].proidvariedade }).toArray()).then((itemVariedade) => {
                  this.lotecompleto = { ...item[0], ...itemVariedade[0] };
                });
                } else {
                  this.lotecompleto = { ...item[0] };
                }
              })
            }) 
          }
        }) 
      }
    },

    w_peso() {
      let novoValor = this.w_peso;
        if (isNaN(novoValor))
            this.w_peso = novoValor;
        novoValor = novoValor.replace('.','');
        if (novoValor.length==1) {
            novoValor = '0.00' + novoValor;
        } else if (novoValor.length==3) {
            novoValor = '0.' + novoValor;
        } else {
            novoValor =  + novoValor.slice(0,-3) +'.' + novoValor.substr(-3);
        }
      this.w_peso = novoValor;
      sessionStorage.setItem('peso', this.w_peso);
    },

    proximolote() {
      sessionStorage.setItem('lote', JSON.stringify(this.proximolote));
    },

    proximoembalagem() {
      sessionStorage.setItem('loteembalagem', JSON.stringify(this.proximoembalagem));
    }
  }

  // watch: {
  //   proximolote(valor) {
  //     this.fabricalocal = (db.aberturalote.where({ fabidfabrica: valor.ablidfabrica  }).toArray())[0];
  //   }
  // }
};
</script>

<style scoped>
  .v-sheet--offset {
    top: 5px;
    position: relative;
  }

  .space {
    margin-bottom: 45px;
  }

  .center {
    width: 100%;
    text-align: center;
  }

  .card {
    padding: 3px;
    margin: 15px 10px 0px 10px
  }

  .card-secador {
    width: 90%;
    text-align: center;
    margin: 20px;
  }

  .linhas {
    height: 21%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }

  .finalizado {
    background-color: #4CAF50;
  }

  .iniciado {
    background-color: #6b93ff;
  }

  .disponivel {
    background-color: #b9b9b9;
  }

  .digitavel {
    margin-bottom: 25px;
  }

    .linha-menor {
    height: 16%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 4px 0px 4px;
    font-size: 13px;
  }

  .inner-card {
    display: flex;
    justify-content: space-between;
    height: 67%;
  }

  .container-inner-items {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .container-inner-items-fill {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .card-btn {
    transform: translateY(10px);
  }

  .txt-center {
    text-align: center;
  }

  .input-container {
      width: 60%;
  }

  .container {
    width: 93vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contaainer {
    width: 93vw;
    max-width: 300px;
    flex-wrap: wrap;
  }

  .textfield {
    width: 90%;
  }

  .n-center >>> input {
      text-align: center;
  }

  .n-center >>> input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .nn-center >>> input {
      text-align: center;
  }

  .nn-center >>> input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .descricaoextra {
    color: #3d68de;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 5px;
    background-color: #fdffd2;
  }

  .centerextra {
      flex: 1;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
  }

  
</style>
