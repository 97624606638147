import { WSmodel } from "../ws/wsmodels";

export default function dadosTabelas() {
  /* 
  nomeapi: => { opcional } NOME DA TABELA NA API,
  campoatt: => CAMPO PARA UTILIZAR NA HORA DA ATUALIZACAO,
  prefixo: => PREFIXO DA TABELA LOCAL,
  model =>  MODEL DO BANCO LOCAL,
  */
  return {
    aberturalote: {
      campoatt: 'abldataalteracao',
      prefixo: 'abl',
      model: WSmodel.aberturalote
    },
    turno: {
      nomeapi: 'turnoproducao',
      campoatt: 'tupdataalteracao',
      prefixo: 'tup',
      model: WSmodel.turno
    },
    localarmazenamento: {
      nomeapi: 'localmovimentacaoestoque',
      campoatt: 'loadataalteracao',
      prefixo: 'loa',
      model: WSmodel.localarmazenamento
    },
    produto: {
      campoatt: 'prodataalteracao',
      prefixo: 'pro',
      model: WSmodel.produto
    },
    fabrica: {
      campoatt: 'fabdataalteracao',
      prefixo: 'fab',
      model: WSmodel.fabrica
    },
    producao: {
      nomeapi: 'ordemproducao',
      campoatt: 'prcdataalteracao',
      prefixo: 'prc',
      model: WSmodel.ordemproducao
    },
    apontamentoproducao: {
      campoatt: 'appdataalteracao',
      prefixo: 'app',
      model: WSmodel.apontamentoproducao
    },
    variedade: {
      campoatt: 'vardataalteracao',
      prefixo: 'var',
      model: WSmodel.variedade
    },
    secagem: {
      campoatt: 'secdataalteracao',
      prefixo: 'sec',
      model: WSmodel.secagem
    },
    logexclusao: {
      model: WSmodel.logexclusao
    }
  }
}


/* export function aberturalote(item) {
    return {
        ablidnuvem: item.id,
        ablidaberturalote: item.ablidaberturalote,
        ablnrocontrole: item.ablnrocontrole,
        abldescricao: item.prodescricao,
        ablgranulometria: item.moddescricao,
        abldataalteracao: item.abldataalteracao,
        abldataabertura: item.abldataabertura,
        ablidproduto: item.ablidproduto,
    }
}
export function turno(item) {
    return {
        tupidnuvem: item.id,
        tupidturno: item.tupidturno,
        tupdataabertura: item.tupdataabertura,
        tupdatafechamento: item.tupdatafechamento,
        tupobservacao: item.tupobservacao,
        tupdataalteracao: item.tupdataalteracao
    }
}
export function localarmazenamento(item) {
    return {
        loaidnuvem: item.id,
        loaidlocalarmazenamento: item.lmeidlocalmovimentacaoestoque,
        loadescricao: item.lmedescricao,
        loadataalteracao: item.lmedataalteracao,
        loacodigoarmazem: item.lmecodigoarmazem,
        loausadoapppesagem: item.lmeusadoapppesagem,
    }
}
export function produto(item) {
    return {
        proidnuvem: item.id,
        prodescricao: item.prodescricao,
        proidproduto: item.proidproduto,
        prodataalteracao: item.prodataalteracao,
        progranulometria: item.moddescricao,
        proidvariedade: item.proidvariedade,
    }
}
export function fabrica(item) {
    return {
        fabidnuvem: item.id,
        fabidfabrica: item.fabidfabrica,
        fabdescricao: item.fabdescricao,
        fabdataalteracao: item.fabdataalteracao,
    }
}
export function ordemproducao(item) {
    return {
        prcidnuvem: item.id,
        prcidproducao: item.orpidordemproducao,
        prcidfabrica: item.orpidfabrica,
        prcdataabertura: item.orpdataabertura,
        prcdatafechamento: item.orpdatafechamento,
        prcobservacao: item.orpobservacao,
        prcnoordemproducao: item.orpnooremproducao,
        prcdataalteracao: moment.utc(item.orpdataalteracaomoment).subtract(3, 'hours').format('YYYY-MM-DDTHH:mm:ss'),
    }
}
export function apontamentoproducao(item) {
    return {
        appidnuvem: item.id,
        appidaberturalote: item.appidaberturalote,
        appdatapesagem: item.appdatapesagem,
        appobsduplicado: item.appobsduplicado,
        appnumerobag: item.appnumerobag,
        appdataalteracao: moment.utc(item.appdataalteracao).subtract(3, 'hours').format('YYYY-MM-DDTHH:mm:ss'),
        appstatus: item.appstatus,
        apppeso: item.apppeso,
        appidlocaltransferenciaproducao: item.appidlocaltransferenciaproducao,
    }
}
export function variedade(item) {
    return {
        varidnuvem: item.id,
        varidvariedade: item.varidvariedade,
        vardescricao: item.vardescricao,
        vardataalteracao: item.vardataalteracao,
    }
} */

