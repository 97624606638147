<template>
    <div class="container">
      <div class="card-wrapper">
          <v-card class="card-secador">  
              <v-icon class="icon" x-large>mdi-silo</v-icon>
            <v-divider></v-divider>
            <v-divider></v-divider>
            <div class="mx-auto align-center">
              <div class="linha-menor">
                <span class="ga-3 font-weight-bold"> {{item.secidaberturalote.ablnrocontrole}} </span>
                <v-btn height="30" class="botaoLupa green white--text" @click="$_emiteLupa">
                  <v-icon class="icon-lupa">mdi-magnify</v-icon>
                </v-btn>
                <span class="ga-3 font-weight-bold"> {{item.secsecador}} </span>
              </div>
            </div>
              <v-divider></v-divider>
            <div class="">
              <div class="linha-menor align-start flex-column">
                <v-btn height="30" width="fit-content" class="botao green white--text my-2">
                  <v-icon class="mr-2">mdi-grain</v-icon>Abastecimento
                </v-btn>
                <v-btn :disabled="!item.secpeso" 
                  height="30" width="fit-content" class="botao green white--text my-2">
                  <v-icon class="mr-2">mdi-button-pointer</v-icon>Operação
                </v-btn>
                <v-btn disabled 
                  height="30" width="fit-content" class="botao green white--text my-2">
                  <v-icon class="mr-2">mdi-home-silo</v-icon>Armazenamento
                </v-btn>
              </div>
            </div>
              <v-divider></v-divider>
            <div class="linha-menor">
              <span> Umidade </span>
              <span> {{item.secumidade}}% </span>
            </div>
              <v-divider></v-divider>
            <div v-if="(item.secstatus === 'D')" class="linha-fechado"> <b> Desativado </b> </div>
            <div v-else-if="(item.secstatus === 'F')" class="linha-fechado"> <b> FINALIZADO </b> </div>
            <div v-else class="linha-aberto"> <b> LIVRE </b> </div> 
          </v-card>
      </div>
    </div>
</template>

<script>

export default {
  name: 'AppCardMonitoramento',


  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
    }
  },

  methods: {
    $_emiteLupa() {
      this.$emit('lupa', this.item)
    }
    },

  created() {
  }
}
</script>

<style scoped>
  .topo {
    justify-content: space-between;
  }
  .v-sheet--offset {
    top: 5px;
    position: relative;
  }
  
  .container:first-child {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .card-wrapper {
    display: flex;
    margin: 0px;
  }
  .icon {
    height: 120px;
    font-size: 6vw !important;
  }

  .icon-lupa {
    height: 120px;
    font-size: 5vh !important;
  }

  .linha-aberto {
    background-color: #4CAF50;
    color: white;
    height: fit-content;
  }

  .linha-fechado {
    background-color: #c0372e;
    color: white;
    height: fit-content;
  }

  .linha-menor {
    height: 12%;
    min-height: 6vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px 0px 15px;
    font-size: 1em;
  }

  .card-secador {
    width: auto;
    height: auto;
    min-width: 20vw;
    text-align: center;
    margin: 20px;
    margin-bottom: 5%;
    position: relative;
  }

  .numero {
    font-size: 15px;
    float: right;
    margin: 0;
    padding: 0;
  }

  .botao {
    width: 100%;
  }
@media screen and (max-width: 800px) {
      .icon {
        font-size: 60px !important;
      }
      .icon-lupa {
        font-size: 5vh !important;
      }
    }
@media screen and (max-width:450px) {
      
      .linha-menor {
        height: 5%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 5px 0px 5px;
        font-size: 0.9em;
      }
      .card-secador {
        width: fit-content;
        height: fit-content;
        text-align: center;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 10px;      
      }
      .icon {
        height: 70px;
        font-size: 50px !important;
      }
      .icon-lupa {
        font-size: 5vw !important;
      }
      .linha-aberto {
        background-color: #4CAF50;
        color: white;
        align-content: center;
        height: fit-content;
        min-height: 10%;
      }
    
      .linha-fechado {
        background-color: #c0372e;
        color: white;
        align-content: center;
        height: fit-content;
        min-height: 10%;
      }
      .botao {
        min-width: 30px !important;
        height: 20px !important ;
        padding-right: 5px !important;
        padding-left: 5px !important;
        font-size: 0.8em;
      }
      .botaoLupa {
        min-width: 30px !important;
        height: 20px !important ;
        padding-right: 10px !important;
        padding-left: 10px !important;
      }
    }
    
</style>