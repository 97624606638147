<template>
  <div>
    <div class="container">
        <v-form ref="form" lazy-validation>
          <form-config
            ref="config"
            :formulario="formulario"
            tabela="apontamentoproducao"
            acaounica="updateByNroControle"
          />
            <div class="d-flex justify-center contaainer">
              <card-auto-complete ref="lote"
                label="Lote P.A."
                class="lote-textfield n-center"
                :dados="lotesdaop" 
                v-model="formulario.ablidaberturalote"
                itemLabel="ablnrocontrole"  
                item-value="ablidaberturalote"
                :return-object="false"
                hide-details
                icon="mdi-qrcode-scan"
                :action="$_onClickQR"
                @keyup.enter="$refs.lote.$children[0].blur(); $refs.posicao_a.focus()"
                :rules="rules.ablidaberturalote"
                :upper="true"
              />
              <v-card class="descricaoextra" v-if="lotecompleto?.abldescricao">
                <b class="centerextra" style="font-weight: 600;">{{lotecompleto?.abldescricao}}</b>
                <v-divider></v-divider>
                <b class="centerextra" style="font-weight: 600;">{{lotecompleto?.ablgranulometria}}</b>
                <v-divider></v-divider>
                <b class="centerextra" style="font-weight: 600;">{{lotecompleto?.vardescricao}}</b>
                <v-divider v-if="lotecompleto?.apppeso"></v-divider>
                <b class="centerextra" style="font-weight: 600;">{{lotecompleto?.apppeso}}</b>
              </v-card>
              <h4 v-if="lotecompleto?.abldescricao">Numero Unidades</h4>
              <v-card class="descricaoextra" v-if="lotecompleto?.abldescricao">
                <b class="centernum" style="font-weight: 600;">{{quantidadeapontamento}}</b>
              </v-card>
              <h4>Posição</h4>
              <div class="linha-qr">
                  <v-text-field 
                  outlined 
                  ref="posicao_a"
                  background-color="white"
                  type=text
                  id=uppercase
                  class="n-center text-uppercase"
                  v-mask="mask"
                  maxLength=1
                  v-model="posicao.a"
                  @keyup.enter="$refs.posicao_a.blur(); $refs.posicao_b.focus()"
                  hide-details>
                  </v-text-field>
                  <v-text-field 
                  outlined 
                  ref="posicao_b"
                  id=uppercase
                  background-color="white"
                  type=number
                  oninput="this.value=this.value.slice(0,2)"
                  class="n-center text-uppercase"
                  v-model="posicao.b"
                  @keyup.enter="$refs.posicao_b.blur(); $refs.posicao_c.focus()"
                  hide-details>
                  </v-text-field>
              </div>
              <div class="linha-qr">
                  <v-text-field 
                  ref="posicao_c"
                  outlined 
                  background-color="white"
                  v-mask="mask"
                  id=uppercase
                  type=text
                  maxLength=1
                  class="n-center text-uppercase"
                  v-model="posicao.c"
                  @keyup.enter="$refs.posicao_c.blur(); $refs.posicao_d.focus()"
                  hide-details>
                  </v-text-field>
                  <v-text-field 
                  ref="posicao_d"
                  outlined 
                  background-color="white"
                  id=uppercase
                  type=number
                  class="n-center"
                  oninput="this.value=this.value.slice(0,2)"
                  v-model="posicao.d"
                  @keyup.enter="$refs.posicao_d.blur(); $refs.posicao_e.focus()"
                  hide-details>
                  </v-text-field>
              </div>
              <div class="linha-qr">
                  <v-text-field 
                  ref="posicao_e"
                  outlined 
                  background-color="white"
                  v-mask="mask"
                  id=uppercase
                  type=text
                  maxLength=1
                  class="n-center"
                  v-model="posicao.e"
                  @keyup.enter="$refs.posicao_e.blur(); $refs.posicao_f.focus()"
                  hide-details>
                  </v-text-field>
                  <v-text-field 
                  ref="posicao_f"
                  outlined 
                  background-color="white"
                  id=uppercase
                  type=number
                  class="n-center"
                  oninput="this.value=this.value.slice(0,2)"
                  v-model="posicao.f"
                  @keyup.enter="$refs.posicao_f.blur(); $refs.posicao_g.focus()"
                  hide-details>
                  </v-text-field>
              </div>
              <div class="linha-qr">
                  <v-text-field 
                  ref="posicao_g"
                  outlined 
                  background-color="white"
                  v-mask="mask"
                  id=uppercase
                  type=text
                  maxLength=1
                  class="n-center"
                  v-model="posicao.g"
                  @keyup.enter="$refs.posicao_g.blur(); $refs.posicao_h.focus()"
                  hide-details>
                  </v-text-field>
                  <v-text-field 
                  ref="posicao_h"
                  outlined 
                  background-color="white"
                  id=uppercase
                  type=number
                  oninput="this.value=this.value.slice(0,2)"
                  v-model="posicao.h"
                  class="n-center"
                  @keyup.enter="$refs.posicao_h.blur(); $_submit"
                  hide-details>
                  </v-text-field>
              </div>
            <div class="space">
            </div>
              <!-- <v-text-field outlined label="Data/Hora" class="textfield n-center" readonly
              v-model="formulario.appdatapesagem"></v-text-field> -->
            <v-btn color="primary" class="salvar" @click="$_submit" width="125px">
              <div v-if="!enviando">Salvar</div>
              <v-progress-circular v-else
                indeterminate
                color="white"
              ></v-progress-circular>
            </v-btn>
            </div>
              <!-- <v-btn @click="$_cancelar">Cancelar</v-btn> -->
        </v-form>
      <!-- <v-text-field></v-text-field> -->
      <br/>
    </div>
  </div>
</template>

<script>

// import errorHandler from '@/utils/error-handler';

import { ROUTE_POSICAO_ARMAZEM, ROUTE_PRODUCAO_CADASTRO, ROUTE_QR  } from '@/constants/ROUTES';

import { mapActions } from 'vuex';

import FormConfig from '@/components/form/components/form-config/Index.vue';
import CardAutoComplete from '@/components/core/card-auto-complete/Index.vue'

import { OPLOCAL_STORAGE } from '@/constants/STORAGE'



// import { OPLOCAL_STORAGE, CADPRODUCAO_STORAGE } from '@/constants/STORAGE';

import db from '@/plugins/dexie';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}


// import AppCardAtividadeSkeleton from '../../components/app/card/atividade/Skeleton.vue';
import { SetupWebSocket } from '../../utils/ws/websocket';
import errorHandler from '@/utils/error-handler';
//import geraData from '@/utils/geradata';
import moment from 'moment-timezone';
import { SetupEnvioLocal } from '@/utils/atualizalocal';

export default {
  name: 'ViewArmazem',

  components: {
    FormConfig,
    CardAutoComplete,
  },

  data() {
    return {
      rotaFiltros: [
        ROUTE_PRODUCAO_CADASTRO,
      ],

      fabrica: null,
      loading: true,
      lotes: null,
      lotesdaop: null,
      lotesfechados: null,
      proximolote: null,
      proximoembalagem: null,
      idordemproducao: null,
      

      formulario: {
        ablidaberturalote: null,
        appposicao: null,
        appdatarobo: null,
      },
      lotecompleto: null,
      quantidadeapontamento: null,

      rules: {
        ablidaberturalote: [ v => !!v || 'Aberturalote é obrigátorio' ],
      },

      // ESSA VIEW ESTÁ COM VARIAS FUNÇÕES A MAIS, RETIRAR DEPOIS
      

      itensComEsseNro: null,

      lotesMaisRecentes: [],

      abrirdialog: false,

      uaaa: false,

      w_peso: null,

      enviando: false,

      posicao: {
        a: '',
        b: '',
        c: '',
        d: '',
        e: '',
        f: '',
        g: '',
        h: ''
      },

      mask: 'A',

    };
  },

  methods: {

    ...mapActions({
      $_aberturaloteUtilizados: 'apontamentoproducao/aberturaloteUtilizados',
    }),

    async $_setup() {
      const lotesValidos = (await this.$_aberturaloteUtilizados()).map((item) => item.ablidaberturalote);
      console.log('lotesvalidos',lotesValidos);
      const allLotes = await db.aberturalote.orderBy('ablnrocontrole').toArray();

      this.lotesdaop = allLotes.filter((item) => {
        if (lotesValidos.includes(item.ablidaberturalote)) {
          return item;
        }
      });

      const formulario = sessionStorage.getItem('formulario', null);
      if (formulario) {
        this.formulario = JSON.parse(formulario);
        await this.$_verificaQR();
        sessionStorage.removeItem('formulario');
      }
    },

    /*

    INPUT SOMENTE LETRA

    NAO FUNCIONA BOTAO APAGAR EM TECLADO FISICO / VERSAO DESKTOP

    */

    async $_submit() {
      if (this.enviando) {
        return;
      }
      this.formulario.appposicao = '';
      const validaPares = await this.$_validaPares();
      if (validaPares) {
          errorHandler(validaPares);
          return;
      }
      console.log(this.formulario, 'formulario');
      if (this.$refs.form.validate()) {
        try {
          this.enviando = true;
          let envio;
          this.formulario.appposicao = this.formulario.appposicao.toUpperCase();
          const lotes = await db.apontamentoproducao.where({ appidaberturalote: this.formulario.ablidaberturalote}).toArray();
          lotes.forEach((item) => {
            envio = db.apontamentoproducao.update(item.id, {
              appdatarobo: this.formulario.appdatarobo,
              appdatanuvem: null,
              appdataalteracao: moment().toISOString(),
              appposicaoarmazem: this.formulario.appposicao
            })
          })
          // const a = await this.$refs.config.$_submit();
          console.log('a', envio);
          if(envio) {
            errorHandler('Enviado com sucesso!');
            //
            SetupEnvioLocal();
            //
            sessionStorage.setItem(OPLOCAL_STORAGE.qr, '');
            Object.keys(this.formulario).forEach(k => this.formulario[k] = null);
            this.$refs.form.resetValidation();
            this.enviando = false;
            this.lotecompleto = null;
            this.posicao =  { a: '', b: '', c: '', d: '', e: '', f: '', g: '', h: ''};
          }
        } catch(e) {
          this.enviando = false;
          console.log(e, 'eeeee');
          if(e.code == 'ERR_NETWORK'){
            errorHandler('Erro de conexão');
          } else {
            errorHandler(e);
          }
        }
      } else {
        errorHandler('Formulario inválido, verifique os dados e envie novamente');
      }
    },

    $_onClickQR() {
      sessionStorage.setItem('formulario', JSON.stringify(this.formulario));
      sessionStorage.setItem('rota-anterior', ROUTE_POSICAO_ARMAZEM.path);
      setTimeout(() => {
        this.$router.push({
          ...this.$route,
          name: ROUTE_QR.name,
        });
      });
    },

    // isLetter(e) {
    //   if (/^[A-Za-z]+$/.test(e)) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },

    async $_verificaQR() {
      const qr = await getSessionStorage(OPLOCAL_STORAGE.qr, '');
      console.log(qr, 'qrqrqr');
      if (qr && qr.length && qr !== 'null') {
        const lote  = (await db.aberturalote.where({ ablnrocontrole: qr }).toArray());
        if (!lote.length) {
          errorHandler('Lote não encontrado');
          return;
        }
        const variedade = (await db.variedade.where({ varidvariedade: lote[0].ablidaberturalote }))
        this.lotecompleto = null;
        this.lotecompleto = { ...lote[0], ...variedade[0] };
        this.formulario.ablidaberturalote = lote[0].ablidaberturalote;
        this.$refs.posicao_a.focus();
        if (typeof this.proximolote === 'object') {
          // this.$_adicionaLote(this.proximolote);
        } else {
          errorHandler('Erro ao adicionar novo lote')
        }
      }
    },

    async $_validaPares() {
      if (this.posicao.a && this.posicao.b) {
        this.formulario.appposicao += `${this.posicao.a}${this.posicao.b}`;
      } else if (this.posicao.a || this.posicao.b) {
        return 'Existem pares não preenchidos';
      }
      if (this.posicao.c && this.posicao.d) {
        if (this.formulario.appposicao) {
          this.formulario.appposicao += `/`;
        }
        this.formulario.appposicao += `${this.posicao.c}${this.posicao.d}`;   
      } else if (this.posicao.c || this.posicao.d) {
        return 'Existem pares não preenchidos';
      }
      if (this.posicao.e && this.posicao.f) {
        if (this.formulario.appposicao) {
          this.formulario.appposicao += `/`;
        }
        this.formulario.appposicao += `${this.posicao.e}${this.posicao.f}`;  
      } else if (this.posicao.e || this.posicao.f) {
        return 'Existem pares não preenchidos';
      }
      if (this.posicao.g && this.posicao.h) {
        if (this.formulario.appposicao) {
          this.formulario.appposicao += `/`;
        }
        this.formulario.appposicao += `${this.posicao.g}${this.posicao.h}`;  
      } else if (this.posicao.g || this.posicao.h) {
        return 'Existem pares não preenchidos';
      }
      if ((Object.values(this.posicao)).every((valor) => !valor)) {
        return 'É necessário ter pelo menos um par';
      }
    },
  },

  created() {
    this.$_setup();
    SetupWebSocket();
    SetupEnvioLocal();
  },

  computed: {
    $$ablidaberturalote() {
      return this.formulario.ablidaberturalote;
    }
  },

  watch: {
    proximolote() {
      sessionStorage.setItem('lote', JSON.stringify(this.proximolote));
    },

    $$ablidaberturalote: {
      handler() {
        setTimeout(() => {
          if (this.formulario.ablidaberturalote) {
            this.lotecompleto = null;
            (db.aberturalote.where({ ablidaberturalote: this.formulario.ablidaberturalote }).toArray()).then((item) => {
              console.log(item[0], 'aaa');
              (db.produto.where({ proidproduto: item[0].ablidproduto }).toArray()).then((itemProduto) => {
                console.log(itemProduto);
                if (itemProduto[0]?.proidvariedade) {
                (db.variedade.where({ varidvariedade: itemProduto[0].proidvariedade }).toArray()).then((itemVariedade) => {
                  this.lotecompleto = { ...item[0], ...itemVariedade[0] };
                  const collection = db.apontamentoproducao.where('appidaberturalote').equals(this.formulario.ablidaberturalote);

                  collection.count().then(count => {
                    this.quantidadeapontamento = count;
                  });
                });
                } else {
                  this.lotecompleto = { ...item[0], };
                  const collection = db.apontamentoproducao.where('appidaberturalote').equals(this.formulario.ablidaberturalote);

                  collection.count().then(count => {
                    this.quantidadeapontamento = count;
                  });
                }
              })
            }) 
          }
        }) 
      }
    },
  }

  // watch: {
  //   proximolote(valor) {
  //     this.fabricalocal = (db.aberturalote.where({ fabidfabrica: valor.ablidfabrica  }).toArray())[0];
  //   }
  // }
};
</script>

<style scoped>
  .v-sheet--offset {
    top: 5px;
    position: relative;
  }

  .space {
    margin-bottom: 45px;
  }

  .center {
    width: 100%;
    text-align: center;
  }

  .card {
    padding: 3px;
    margin: 15px 10px 0px 10px
  }

  .card-secador {
    width: 90%;
    text-align: center;
    margin: 20px;
  }

  .linhas {
    height: 21%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }

  .finalizado {
    background-color: #4CAF50;
  }

  .iniciado {
    background-color: #6b93ff;
  }

  .disponivel {
    background-color: #b9b9b9;
  }

  .digitavel {
    margin-bottom: 25px;
  }

    .linha-menor {
    height: 16%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 4px 0px 4px;
    font-size: 13px;
  }

  .inner-card {
    display: flex;
    justify-content: space-between;
    height: 67%;
  }

  .container-inner-items {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .container-inner-items-fill {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .card-btn {
    transform: translateY(10px);
  }

  .txt-center {
    text-align: center;
  }

  .input-container {
      width: 60%;
  }

  .container {
    width: 93vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contaainer {
    width: 93vw;
    max-width: 250px;
    flex-wrap: wrap;
  }

  .lote-textfield {
    width: 90%;
  }

  .n-center >>> input {
      text-align: center;
  }

  .n-center >>> input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .nn-center >>> input {
      text-align: center;
  }

  .nn-center >>> input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .linha-qr {
    display: flex;
    padding: 4px;
  }

  h4 {
    font-weight: 300;
  }

  .salvar {
    margin-top: 20px;
  }

  /deep/ #uppercase {
      text-transform: uppercase;
}

  .descricaoextra {
    color: #3d68de;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 5px;
    background-color: #fdffd2;
  }

  .centerextra {
      flex: 1;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
  }

    .centernum {
      flex: 1;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
  }

  
</style>
