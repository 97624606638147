/* eslint-disable no-console */

import { register } from 'register-service-worker'
import SnackbarEvent from './events/SnackbarEvent'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated(registration) {
      console.log('New content is available; please refresh.');

      const { waiting } = registration;
      if (!waiting) return;

      document.dispatchEvent(new SnackbarEvent({
        message: 'App atualizado, recarregando',
        icon: 'mdi-refresh',
        closeable: false,
        timeout: 6000,
        // action: {
        //   text: i18n.t('pwa.update'),
        //   handler: () => {
        //     waiting.postMessage({
        //       type: 'SKIP_WAITING',
        //     });
        //   },
        // },
      }));

      setTimeout(() => {
        waiting.postMessage({
          type: 'SKIP_WAITING',
        });
      }, 2000);
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}

let refreshing = false;
if (process.env.NODE_ENV === 'production') {
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    if (refreshing) return;
  
    refreshing = true;
    window.location.reload();
  });
}
