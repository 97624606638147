import axios from 'axios';

import wsroutes from './routes/wsroutes';
import geraData from '../geradata';
//import { USER_STORAGE } from '@/constants/STORAGE';

let wsapi = null;
export let wsConnection = null;
export const wsParametros = {
  connectionId: null,
  conectado: false,
};
let interval = null;


export async function WSDefaultRoute() {
wsLog('INFO', `Rodando em => ${process.env.NODE_ENV}`, false, '#0fa', '⚙️', "")
if (process.env.NODE_ENV !== 'production') return;
const { data } = await axios.get('/api-ws-base-url');
wsapi = data;
}

export async function SetupWebSocket() {
  // wsapi = axios.get('/api-ws-base-url');
  // eslint-disable-next-line no-constant-condition
  if (true) {
    return;
  }
  if (process.env.NODE_ENV === 'production' && !wsConnection) {
    wsConnection = new WebSocket(wsapi);
  } else if (!wsConnection && !wsParametros.connectionId) {
    wsConnection = new WebSocket('ws://localhost:3001');
  } else {
    return;
  }
  wsParametros.conectado = true;
  wsConnection.onmessage = ((msg) => {
    let data;
    try {
      data = JSON.parse(msg.data);
    } catch {
      return;
    }
    if (data.tipo === 'novoid') { wsParametros.connectionId = data.data }
    wsLog('RECEBEU', data.tipo, data.data, '#fa87fa', '🟪');
    wsroutes[data.tipo]({ data: data.data, wsConnection, wsParametros });
  });

  wsConnection.onopen = (() => {
    
    wsLog('WS', 'Conexão Aberta', false, '#0f0', '✔️');
    if (interval) {
      clearInterval(interval);
    }
  });

  wsConnection.onclose = (() => {
    wsLog('WS', 'Conexão Encerrada', false, '#f61', '⛔');
    wsParametros.conectado = false;
    wsParametros.connectionId = null;
    wsConnection = null;
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(() => {
      if (navigator.onLine) {
        SetupWebSocket();
      }
    }, 3000);
  });

  wsConnection.onerror = (() => {
    wsLog('WS', 'Erro de Conexão', false, '#ff4242', '❌');
  });
}

export async function CloseWebSocket() {
  wsConnection.close();
}

//

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}

export async function WsEnviaDados({ tipo, msg }) {
  const wsConnectionId = wsParametros.connectionId;
  const token = await getSessionStorage('auth:token', null);
  const obj = {
      msg,
      tipo,
      connectionId:
      wsConnectionId,
      token,
  };
  wsLog('ENVIOU', tipo, obj.msg, '#0ff', '🟦');
  wsConnection.send(JSON.stringify(obj));
}

export function wsLog(acao, titulo, corpo, cor, icone, data = geraData()) {
  console.log(`%c${icone} ${acao}: ` + titulo + " | " + data, `color: ${cor}; font-weight: bold;`);
  if (corpo) {
    console.log(`%c>>>`,  `color: ${cor}`, corpo);
    console.log("");
  }
}
