import store  from '../store';
import db from '@/plugins/dexie';
import { TOKEN, USER_STORAGE } from '@/constants/STORAGE'

function getSessionStorage(key,defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}
export default async function conexao() {
  // Login do Usuário caso se conectou offline.
  const authToken = getSessionStorage(TOKEN, '');
  const useridnuvem = getSessionStorage(USER_STORAGE.useidnuvem,'');
  const payload = await db.usuario.where({useidnuvem: useridnuvem}).first();
  if(!authToken) {
    try {
      const user = {
        cpf: payload.usecpf,
        password: payload.usesenha,
      }
      store.dispatch('auth/authenticate', user)
      
    } catch (err) {
      console.log(err)
    }
  }
}
