export const ROUTE_HOME = {
  path: '/home',
  name: 'home',
};

export const ROUTE_ABOUT = {
  path: '/about',
  name: 'about',
};

export const ROUTE_CADASTRO = {
  path: '/lote/cadastro',
  name: 'cadastrolote',
};

export const ROUTE_PRODUCAO_CADASTRO = {
  path: '/producao/cadastro',
  name: 'cadastroproducao',
};

export const ROUTE_PRODUCAO = {
  path: '/producao',
  name: 'producao',
};

export const ROUTE_PRODUCAO_B = {
  path: '/loteb',
  name: 'loteb',
};

export const ROUTE_PRODUCAO_C = {
  path: '/lotec',
  name: 'lotec',
};

export const ROUTE_SECADOR = {
  path: '/secador',
  name: 'secador',
};

export const ROUTE_SIGN_IN = {
  path: '/sing-in',
  name: 'sign-in',
};

export const ROUTE_LOTE = {
  path: '/lote',
  name: 'lote',
};

export const ROUTE_QR = {
  path: '/qr/:tipo',
  name: 'qr',
};

export const ROUTE_PRODUCAO_READONLY = {
  path: '/apontamento/readonly',
  name: 'readonly',
}

export const ROUTE_LOTES_FINALIZADOS = {
  path: '/lote/finalizados',
  name: 'lotesfinalizados',
}

export const ROUTE_POSICAO_ARMAZEM = {
  path: '/posicaoarmazem',
  name: 'posicaoarmazem',
}

export const ROUTE_TRANSFERENCIA_PRODUCAO = {
  path: '/transferenciaproducao',
  name: 'transferenciaproducao',
}

export const ROUTE_MONITORAMENTO = {
  path:'/monitoramento',
  name: 'monitoramento',
}