<template>
  <layout-default-appbar filterabl>
      Monitoramento
  </layout-default-appbar>
</template>

<script>
import LayoutDefaultAppbar from '@/layouts/default/AppBar.vue';
import { ROUTE_MONITORAMENTO } from '@/constants/ROUTES';

export default {
  name: 'ViewFrotaAppBar',

  components: {
    LayoutDefaultAppbar,
  },

    data() {
    return {
      rota: ROUTE_MONITORAMENTO,
    }
  }

};
</script>
