import db from "@/plugins/dexie";
import { WsEnviaDados } from "../../websocket";
import geraData from "@/utils/geradata";
import moment from "moment-timezone";

export default {
    enviaApontamentoProducao: () => {
        const apontamento = db.apontamentoproducao.where('appidnuvem').equals('null').toArray();
        
        apontamento.then((tabela) => {
            tabela.forEach((app) => {
                console.log('app', app)
                WsEnviaDados({tipo: 'postApontamentoProducao', msg: {...app, appidusuario: sessionStorage.getItem('user:useidnuvem')}})
            });
        });
    },
    retornoApontamentoProducao: ({ data }) => {
        const item = data.body;
        const datanuvem = moment.utc(item.appdataalteracao).subtract(3, 'hours').toISOString();
        const queryGeraData = db.apontamentoproducao.where({ appdatapesagem: geraData(item.appdatapesagem) }).toArray();
        queryGeraData.then((tabela) => {
            console.log('tabela', tabela)
            tabela.forEach((linha) => {
                console.log('linha', linha)
                db.apontamentoproducao.update(linha.id, {appidnuvem: item.id, appdatanuvem: datanuvem, appdataalteracao: datanuvem});
            })
        })
    },
}